(function ($) {


    $(document).ready(function () {


        // WIETSE, HELPED!
        $('.gfield input, .gfield textarea').focus(function () {

            $(".gfield").each(function () {
                $('.gfield').removeClass('is-focussed');
            });

            $(this).closest('.gfield').addClass('is-focussed is-active');
        });

        $('.gfield input, .gfield textarea').focusout(function () {

            $(".gfield").each(function () {
                $('.gfield').removeClass('is-focussed');
            });


            if ($(this).val().length > 0) {
                //  $(this).closest('.gfield').addClass('is-set');
            }
            else {
                $(this).closest('.gfield').removeClass('is-active');

            }

        });

        // Set the label to the top in these special occasions
        $(".ginput_container_select, .ginput_container_fileupload, ginput_container_checkbox").each(function () {
            $(this).closest('.gfield').addClass('is-active');
        });

        // Modal window
        $('.js-modal__toggle').click(function () {
            $('.c-modal').toggleClass('is-open');
            $('.c-layout').toggleClass('is-hidden');
            $('body').scrollTop(0);
        });

        $(document).keydown(function(e){

            if(e.keyCode == 27) {

                if ($('.c-modal').hasClass('is-open')) {
                    console.log('clik');
                    $('.c-modal').toggleClass('is-open');
                } else {

                }
            }
        });

        $('.js-modal__close').click(function () {
            $(".c-modal").removeClass('is-open').each(function () {
                $('.c-modal__body').removeClass('is-active');
            });
            $('.c-layout').removeClass('is-hidden');
            $('body').scrollTop(0);
        });

        $("img.js-lazy").lazyload({
            threshold: 1000
        });

        $('.c-carousel').flickity({
            // options
            cellAlign: 'center',
            initialIndex: 1,
            cellSelector: '.c-carousel__item',
            contain: true,
            freeScroll: false,
            wrapAround: true,
                        fullscreen: true,

            pageDots: false,
            bgLazyLoad: 2,
            prevNextButtons: true,
            selectedAttraction: 0.2,
            friction: 0.8
        });

        // Cookie Bullit
        var hasSeen = localStorage.getItem('cookieBullit');


        if (hasSeen == null) {



            $('.c-button--absolute').addClass('activated');

            $('.c-button--absolute').on('click', function () {
                $('.c-button--absolute').removeClass('activated');
                localStorage.setItem('cookieBullit', "true");
                hasSeen = true;


            });

        }
        else{


            $('.c-button--absolute').removeClass('activated');
        }
        // End Cookie Bullit

        if( /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent) ) {
            // run your code here
            setTimeout(function () {   window.scrollTo(0, 1); }, 1000);
        }


        });
}
(jQuery));	
